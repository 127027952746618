import { useCallback, useMemo } from 'react';
import { VariantInventory } from 'shared/types/product';
import useSWR from 'swr';
import { sdk } from 'sdk';

type GetInventoryResponse = {
  isError: boolean;
  data: VariantInventory[];
};

type UseProductInventoryResult = {
  inventoryIsLoading: boolean;
  inventoryIsValidating: boolean;
  inventoryHasError: boolean;
  inventory: VariantInventory[];
  refetchInventory: () => void;
  getVariantInventory: (skuId: string) => number;
};

export const useProductInventory = (productId?: string): UseProductInventoryResult => {
  const shouldFetch = !!productId;

  const { isLoading, isValidating, error, data, mutate } = useSWR<GetInventoryResponse>(
    shouldFetch ? productId : null,
    (productId: string) =>
      sdk
        .callAction<GetInventoryResponse>({
          actionName: 'product/getInventory',
          query: { productId },
        })
        .then((res) => {
          if (res.isError) throw new Error('Error fetching inventory');
          return res.data;
        }),
    {
      shouldRetryOnError: false,
      revalidateIfStale: true,
    },
  );

  const inventory: VariantInventory[] = useMemo(() => (Array.isArray(data) ? data : []), [data]);

  const getVariantInventory = useCallback(
    (skuId: string) => {
      return inventory.find((i) => i.skuId === skuId)?.quantity || 0;
    },
    [inventory],
  );

  return {
    inventoryIsLoading: isLoading,
    inventoryIsValidating: isValidating,
    inventoryHasError: !!error,
    inventory: Array.isArray(data) ? data : [],
    refetchInventory: mutate,
    getVariantInventory,
  };
};
