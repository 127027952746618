export enum COMPONENT_TYPE {
  BUTTON = 'button',
  LINK = 'link',
  RADIO_BUTTON = 'radio',
  TAB = 'tab',
  CHECKBOX = 'checkbox',
  PRODUCT_DETAILS = 'product_details',
  CART_DETAILS = 'cart_details',
  EVENT = 'event',
  SEARCH = 'search',
  PRODUCT_CAROUSEL = 'product_carousel',
  INVENTORY_GRID = 'inventory_grid',
  SEARCH_QUERY = 'search_query',
  LOGO = 'logo',
  ACCORDION = 'accordion',
  ICON = 'icon',
}
