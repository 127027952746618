import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';

export const checkIfSameRoute = (url: string, router: any) => {
  const newPath = url.split('?')[0];
  const currentPath = router.asPath.split('?')[0];

  if (newPath === currentPath) return true;
  return false;
};

export const useConfirmLeave = (isActive: boolean) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });

  const leavePageWarning = formatMessage({
    id: 'leave.page.warning',
    defaultMessage: 'Changes made on this page will be lost.',
  });

  const handleBeforeunload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!isActive) return;
      event.preventDefault();
    },
    [isActive],
  );

  const handleRouteChangeStart = useCallback(
    (url) => {
      if (!isActive) return;
      const isSameRoute = checkIfSameRoute(url, router);
      if (isSameRoute) return;
      if (window.confirm(leavePageWarning)) return true;
      throw 'Aborted route change';
    },
    [isActive],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload);
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [handleBeforeunload, handleRouteChangeStart]);
};
