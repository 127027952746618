import { useCallback } from 'react';
import { mutate } from 'swr';

import {
  ContextResponseMapper,
  PaymentDetailsResponse,
  RequestCyberSourceDataMapper,
  RequestResponsePaymentSessionMapper,
  RequestSaveTokenMapper,
} from './types';
import { sdk } from 'sdk';

const useCybersource = () => {
  const generateCaptureContext = useCallback(async (requestBody: RequestResponsePaymentSessionMapper) => {
    const response = await sdk.callAction({
      actionName: 'cybersource/generateCaptureContext',
      payload: requestBody,
    });

    return {
      isError: response.isError,
      error: response.isError ? JSON.parse(response.error.message.split('Error: ')[1]) : undefined,
      data: !response.isError ? response.data : undefined,
    } as ContextResponseMapper;
  }, []);

  const getPaymentDetails = useCallback(async (requestBody: RequestCyberSourceDataMapper) => {
    const response = await sdk.callAction({
      actionName: 'cybersource/getPaymentDetails',
      payload: requestBody,
    });

    return { isError: response.isError, data: response.isError ? undefined : response.data } as PaymentDetailsResponse;
  }, []);

  const updatePaymentDetail = useCallback(async (requestBody: RequestCyberSourceDataMapper) => {
    const response = await sdk.callAction({
      actionName: 'cybersource/updatePaymentDetail',
      payload: requestBody,
    });
    return { isError: response.isError, data: response.isError ? undefined : response.data } as PaymentDetailsResponse;
  }, []);

  const saveTokenCC = useCallback(async (requestBody: RequestSaveTokenMapper) => {
    const response = await sdk.callAction({
      actionName: 'cybersource/saveToken',
      payload: requestBody,
    });
    return {
      isError: response.isError,
      error: response.isError ? JSON.parse(response.error.message.split('Error: ')[1]) : undefined,
      data: response.isError ? undefined : response.data,
    } as PaymentDetailsResponse;
  }, []);

  mutate('/action/cybersource/getPaymentDetails');

  return { generateCaptureContext, getPaymentDetails, updatePaymentDetail, saveTokenCC };
};

export default useCybersource;
