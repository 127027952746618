import { Guid } from './guid';

export const getRetryServerOptions = {
  serverOptions: {
    req: {
      method: 'POST',
      headers: {
        'coFE-Custom-Configuration': JSON.stringify({ 'x-lll-fe-retry-correlation-id': `${Guid.newGuid(true)}` }),
      },
    } as any,
  },
};

export const removeApplicationData = () => {
  var Cookies = document.cookie.split(';');

  // set 1 Jan, 1970 expiry for every cookies
  for (var i = 0; i < Cookies.length; i++) {
    document.cookie = Cookies[i] + '=;expires=' + new Date(0).toUTCString();
  }

  localStorage.clear();
  sessionStorage.clear();
};

export const parseJwt = (token: string) => {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
};
