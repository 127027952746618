import { FormikErrors } from 'formik';
import { useFormat } from 'helpers/hooks/useFormat';
import { CartFormikValues } from 'types/cart';
import { useProductInventory } from './useProductInventory';

export const useAddCartFormikValidation = (productId?: string) => {
  const { getVariantInventory } = useProductInventory(productId);
  const { formatMessage } = useFormat({ name: 'inventory-grid' });
  const exceedsQuantity = formatMessage({
    id: 'exceeds.quantity',
    defaultMessage: 'Exceeds available quantity. Please reduce the amount.',
  });
  const noZeroAllowed = formatMessage({
    id: 'greater.than.zero',
    defaultMessage: 'Please enter a value greater than 0.',
  });

  const validate = (values: CartFormikValues): FormikErrors<CartFormikValues> => {
    const errors: FormikErrors<CartFormikValues> = {};

    Object.entries(values).forEach(([sku, qty]) => {
      const availableInventory = getVariantInventory(sku);
      if (qty > availableInventory) {
        errors[sku] = exceedsQuantity;
      }
      if (qty === 0) {
        errors[sku] = noZeroAllowed;
      }
    });

    return errors;
  };

  return validate;
};
