import { NgcCart } from 'shared/types/ngcCart';
import { CartSkuItem, AddCartItemsResponse } from 'types/cart';

const unique = <T>(arr: T[]): T[] => [...new Set(arr)];

export const makeAddCartItemsResponse = (cart: NgcCart, skus: CartSkuItem[]): AddCartItemsResponse => {
  const addedItems = (cart.lineItems ?? [])
    .filter((item) => skus.some((sku) => sku.skuId === item.skuId))
    .map(({ skuId, productName, size, ...item }) => ({
      productName,
      imageUrl: item.images,
      colorName: item.colorDescription,
      size,
      wholesalePrice: item.lineItemUnitPrice.salePrice,
      quantity: skus.find((sku) => sku.skuId === skuId)?.quantity ?? 0,
    }));

  const productName = addedItems[0].productName ?? '';
  const imageUrl = addedItems[0].imageUrl;
  const colorNames = unique(addedItems.map((item) => item.colorName));
  const sizes = unique(addedItems.map((item) => item.size));
  const quantity = addedItems.reduce((acc, { quantity }) => acc + quantity, 0);
  const totalPrice = addedItems.reduce((acc, { quantity, wholesalePrice }) => acc + quantity * wholesalePrice, 0);
  const lineItemsTotalPrice = cart.cartTotal.lineItemTotal?.subtotalBeforeDiscount ?? 0;
  const orderQuantity = cart.totalQuantity;
  const orderSubtotal = cart.cartTotal.subtotal ?? 0;
  const currencyCode = cart.cartTotal.currencyCode;

  return {
    productName,
    imageUrl,
    colorNames,
    sizes,
    quantity,
    totalPrice,
    lineItemsTotalPrice,
    orderQuantity,
    orderSubtotal,
    currencyCode,
  };
};
