import { createContext, useContext, useEffect, useState, ReactNode, Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/router';
import { OverlayLoader } from 'components/overlayLoader';

type LoadingOverlayContextValue = {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const LoadingOverlayContext = createContext<LoadingOverlayContextValue>({} as LoadingOverlayContextValue);

export const LoadingOverlayProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isSameRoute = (url: string) => {
      const newPath = url.split('?')[0];
      const currentPath = router.asPath.split('?')[0];

      if (newPath === currentPath) {
        return true;
      }
      return false;
    };

    const handleRouteChangeStart = (url: string) => {
      if (!isSameRoute(url)) {
        setLoading(true);
      }
    };
    const handleRouteChangeEnd = () => setLoading(false);
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeEnd);
    };
  }, [router]);

  return (
    <LoadingOverlayContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {loading ? <OverlayLoader color="white" /> : null}
      {children}
    </LoadingOverlayContext.Provider>
  );
};

export const useLoadingOverlayContext = () => {
  const context = useContext(LoadingOverlayContext);
  if (!context) {
    throw new Error('useLoadingOverlayContext must be used within a <LoadingOverlayProvider />');
  }
  return context;
};
