const localizationMapper = {
  //   Customize the mapper to meet your projects needs
  gbn: {
    locale: 'en_GB',
    currency: 'GBP',
    currencyCode: '£',
    countryCode: 'GB',
    countryName: 'United Kingdom',
  },
  en_US: {
    locale: 'en_US',
    currency: 'USD',
    currencyCode: '$',
    countryCode: 'US',
    countryName: 'United States',
    shortName: 'USA',
  },
  fr_CA: {
    locale: 'fr_CA',
    currency: 'CAD',
    currencyCode: '$',
    countryCode: 'CAN',
    countryName: 'Canada',
    language: 'Français',
  },
  en_CA: {
    locale: 'en_CA',
    currency: 'CAD',
    currencyCode: '$',
    countryCode: 'CAN',
    countryName: 'Canada',
    language: 'English',
  },
};

const getLocalizationInfo = (locale) => {
  if (!(locale in localizationMapper)) {
    console.warn(
      `Invalid locale ${locale} provided. Possible values are ${Object.keys(localizationMapper).join(', ')}`,
    );

    return localizationMapper.en_US;
  }

  return localizationMapper[locale];
};

module.exports = { getLocalizationInfo };
