import { FormikErrors } from 'formik';
import { useFormat } from 'helpers/hooks/useFormat';
import { CartFormikValues, LineItemValues } from 'types/cart';
import { useProductInventory } from './useProductInventory';

export const useEditCartFormikValidation = (lineItemValues: LineItemValues, productId?: string) => {
  const { getVariantInventory } = useProductInventory(productId);
  const { formatMessage } = useFormat({ name: 'inventory-grid' });
  const exceedsQuantity = formatMessage({
    id: 'exceeds.quantity',
    defaultMessage: 'Exceeds available quantity. Please reduce the amount.',
  });
  const noEmptyAllowed = formatMessage({
    id: 'no.empty.allowed',
    defaultMessage: 'Please enter a value.',
  });

  const validate = (values: CartFormikValues): FormikErrors<CartFormikValues> => {
    const errors: FormikErrors<CartFormikValues> = {};

    Object.entries(values).forEach(([sku, qty]) => {
      const availableInventory = getVariantInventory(sku);

      const { quantityReserved } = lineItemValues[sku];
      // maxQty must use quantityReserved because partial reservations mean cart quantity may not equal reserved quantity
      const maxQty = availableInventory + quantityReserved;
      if (qty > maxQty) {
        errors[sku] = exceedsQuantity;
      }
      if (typeof qty === 'string') {
        errors[sku] = noEmptyAllowed;
      }
    });

    return errors;
  };

  return validate;
};
