import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { NgcCart, NgcOrder, ShippingMethod } from 'shared/types/ngcCart';
import { extractDataFromSdkResponse, makeAddCartItemsResponse } from 'helpers';
import { getRetryServerOptions } from 'helpers/utils/requestHelper';
import { sdk } from 'sdk';
import { CartLineItem, CartSkuItem, AddCartItemsResponse } from 'types/cart';
import { useAccount } from 'frontastic/hooks';
import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';
import { useAnalytics } from 'hooks/useAnalytics';
import { CART } from 'helpers/utils/googleAnalyticsEvents/cart';

export type PaymentMethodPayload = {
  paymentMethod: 'creditCard' | 'AR';
  partnerUUID?: string;
  billingAddressId?: string;
};

type CartContextValue = {
  addCartItems: (payload: CartSkuItem[], isRetry?: boolean) => Promise<AddCartItemsResponse>;
  addPaymentMethod: (payload: PaymentMethodPayload) => Promise<void>;
  addPromoCode: (code: string) => Promise<void>;
  cart: NgcCart | null;
  cartHasLoadingError: boolean;
  cartLoading: boolean;
  getShippingMethods: () => Promise<ShippingMethod[]>;
  placeOrder: () => Promise<NgcOrder>;
  initializeCart: () => Promise<NgcCart>;
  removeCartItems: (payload: CartLineItem[]) => Promise<void>;
  removePromoCode: (promoCode: string) => Promise<void>;
  resetCart: () => void;
  updateCartItems: (payload: CartLineItem[]) => Promise<void>;
  updatePaymentMethod: (payload: PaymentMethodPayload) => Promise<void>;
  updatePoNumberToCart: (poNumber: string) => Promise<void>;
  updateShippingAddress: (shippingAddressId: string, isRetry: boolean) => Promise<void>;
  updateShippingMethod: (id: string, isRetry: boolean) => Promise<NgcCart>;
  resetCartSession: () => Promise<{}>;
};

const CartContext = createContext<CartContextValue>({} as CartContextValue);

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const { accountLoading, isUserLoggedIn } = useAccount();
  const { isFeatureEnabled, featureFlagsList } = useFeatureFlags();
  const [cartLoading, setCartLoading] = useState<boolean>(true);
  const [cartHasLoadingError, setCartHasLoadingError] = useState<boolean>(false);
  const [cart, setCart] = useState<NgcCart | null>(null);

  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  const initializeCart = async () => {
    let response: any = null;
    try {
      response = await sdk.callAction<NgcCart>({
        actionName: 'cart/getCart',
      });

      setCartLoading(false);
      if (response.isError) {
        setCartHasLoadingError(true);
      } else {
        setCart(response.data);
      }
    } catch (error) {
      setCartHasLoadingError(true);
    } finally {
      return response.isError ? {} : response.data;
    }
  };

  useEffect(() => {
    if (!accountLoading && isUserLoggedIn && isFeatureEnabled(FEATURE_FLAG_LIST.CART) && cart === null) {
      initializeCart();
    }
  }, [accountLoading, isUserLoggedIn, featureFlagsList, cart, initializeCart]);

  const resetCart = () => {
    setCartLoading(true);
    setCartHasLoadingError(false);
    setCart(null);
  };

  /*
   *  UF methods
   */
  const addCartItems = async (payload: CartSkuItem[], isRetry?: boolean): Promise<AddCartItemsResponse> => {
    const response = await sdk.callAction<NgcCart>({
      actionName: 'cart/addToCart',
      payload,
      query: isRetry
        ? {
            isRetry,
          }
        : undefined,
    });

    const data = extractDataFromSdkResponse(response);
    setCartHasLoadingError(false);
    // Cart id is created at this point when user add item for the first time
    if (!cart?.cartId) {
      trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, CART.CART_INITIALIZED(data.cartId));
    }
    setCart(data);
    return makeAddCartItemsResponse(data, payload);
  };

  const updateCartItems = async (payload: CartLineItem[]): Promise<void> => {
    const response = await sdk.callAction<NgcCart>({
      actionName: 'cart/updateCartLineItems',
      payload,
    });
    const data = extractDataFromSdkResponse(response);
    setCart(data);
  };

  const removeCartItems = async (payload: CartLineItem[]): Promise<void> => {
    const response = await sdk.callAction<NgcCart>({
      actionName: 'cart/removeCartLineItems',
      payload,
    });
    const data = extractDataFromSdkResponse(response);
    setCart(data);
  };

  /*
   *  LF methods
   */
  const updatePoNumberToCart = async (poNumber: string) => {
    const res = await sdk.callAction<NgcCart>({
      actionName: 'cart/updatePoNumberToCart',
      payload: {
        data: {
          type: 'carts',
          attributes: {
            poNumber,
          },
        },
      },
    });

    const data = extractDataFromSdkResponse(res);
    setCart(data);
  };

  const addPaymentMethod = async (payload: PaymentMethodPayload) => {
    const response = await sdk.callAction<NgcCart>({
      actionName: 'cart/addPaymentMethod',
      payload,
    });

    const data = extractDataFromSdkResponse(response);
    setCart(data);
  };

  const updatePaymentMethod = async (payload: PaymentMethodPayload) => {
    const response = await sdk.callAction<NgcCart>({
      actionName: 'cart/updatePaymentMethod',
      payload,
    });

    const data = extractDataFromSdkResponse(response);
    setCart(data);
  };

  const updateShippingAddress = async (shippingAddressId: string, isRetry = false) => {
    const res = await sdk.callAction<NgcCart>({
      actionName: 'cart/updateShippingAddressToCart',
      payload: {
        data: [
          {
            type: 'shippingAddress',
            attributes: {
              shippingAddressId,
            },
          },
        ],
      },
      serverOptions: isRetry ? getRetryServerOptions.serverOptions : undefined,
    });

    const data = extractDataFromSdkResponse(res);
    setCart(data);
  };

  const getShippingMethods = async () => {
    const res = await sdk.callAction<ShippingMethod[]>({
      actionName: 'cart/getShippingMethods',
    });

    const data = extractDataFromSdkResponse(res);
    return data;
  };

  const updateShippingMethod = async (id: string, isRetry = false) => {
    const res = await sdk.callAction<NgcCart>({
      actionName: 'cart/updateShippingMethodToCart',
      payload: {
        data: [
          {
            type: 'shippingMethod',
            id,
          },
        ],
      },
      query: isRetry
        ? {
            isRetry,
          }
        : undefined,
      serverOptions: isRetry ? getRetryServerOptions.serverOptions : undefined,
    });
    const data = extractDataFromSdkResponse(res);
    setCart(data);
    return data;
  };

  const addPromoCode = async (code: string) => {
    const isPromoApplied = Boolean(cart?.promotions?.code);
    const res = await sdk.callAction<NgcCart>({
      actionName: 'cart/addPromoCode',
      payload: {
        data: [
          {
            type: 'promotions',
            attributes: {
              code,
            },
          },
        ],
        isPromoApplied,
      },
    });

    const data = extractDataFromSdkResponse(res);
    setCart(data);
  };

  const removePromoCode = async (promoCode: string) => {
    const res = await sdk.callAction<NgcCart>({
      actionName: 'cart/removePromoCode',
      payload: {
        promoCode,
      },
    });

    const data = extractDataFromSdkResponse(res);
    setCart(data);
  };

  const placeOrder = async () => {
    const response = await sdk.callAction<NgcOrder>({
      actionName: 'cart/placeOrder',
    });

    return extractDataFromSdkResponse(response);
  };

  const resetCartSession = async () => {
    try {
      const response = await sdk.callAction({
        actionName: 'cart/resetCartSession',
      });
      resetCart();

      return response;
    } catch (error) {
      console.error('Error resetting cart session:', error);
      throw error;
    }
  };

  return (
    <CartContext.Provider
      value={{
        addCartItems,
        addPaymentMethod,
        addPromoCode,
        cart,
        cartHasLoadingError,
        cartLoading,
        getShippingMethods,
        removeCartItems,
        removePromoCode,
        resetCart,
        updateCartItems,
        updatePaymentMethod,
        updatePoNumberToCart,
        updateShippingAddress,
        updateShippingMethod,
        placeOrder,
        initializeCart,
        resetCartSession,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a <CartProvider />');
  }
  return context;
};
