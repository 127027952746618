import { LoadingIndicator } from '@lululemon/ecom-pattern-library';
import styles from './overlayLoader.module.scss';

interface OverlayLoaderProps {
  color?: 'red' | 'gray' | 'white';
  name?: string;
}

export const OverlayLoader = ({ color, name }: OverlayLoaderProps) => {
  return (
    <div className={styles.overlay}>
      <LoadingIndicator color={color} test-id={`${name}__loader_test-id}`} />
    </div>
  );
};
