import { useMemo, useCallback } from 'react';
import { Address } from 'shared/types/account';
import { BusinessUnit } from 'shared/types/businessUnit';
import { ErrorType } from 'shared/types/error';
import useSWR, { mutate } from 'swr';
import { useFormat } from 'helpers/hooks/useFormat';
import { sdk } from 'sdk';
import { UseBusinessUnitReturn } from './types';

const useBusinessUnit = (): UseBusinessUnitReturn => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const fetchBusinessUnitInfo = async () => {
    const response = await sdk.callAction({ actionName: 'businessUnit/fetchBusinessUnitInfo' });
    return response;
  };

  const businessUnitResponse = useSWR('/action/businessUnit/getBusinessUnitInfo', fetchBusinessUnitInfo);

  const data = useMemo(() => {
    if (businessUnitResponse.data?.isError) {
      return {
        businessUnit: undefined as any,
        error: businessUnitResponse.data?.error,
        isLoading: false,
      };
    }

    const data = businessUnitResponse.data?.data as any;

    return {
      businessUnit: data ?? {},
      isLoading: Boolean(!data),
    };
  }, [businessUnitResponse]);

  const customInvoiceTerms = useMemo(() => {
    return data?.businessUnit?.custom?.fields?.invoice_terms || '';
  }, [data]);

  const showAccountsReceivable = useMemo(() => data?.businessUnit?.custom?.fields?.invoice_flag ?? false, [data]);

  const showCreditCards = useMemo(() => data?.businessUnit?.custom?.fields?.credit_card_flag ?? false, [data]);

  const showCreditCardsAndAR = useMemo(
    () => (showAccountsReceivable && showCreditCards) ?? false,
    [showAccountsReceivable, showCreditCards],
  );

  const shippingAddresses = useMemo(() => {
    if (!Array.isArray(data?.businessUnit?.addresses) || !data?.businessUnit.addresses.length) {
      return [];
    }

    return (data?.businessUnit?.addresses ?? []).filter((address: Address) => address.isShippingAddress);
  }, [data?.businessUnit]);

  const billingAddresses = useMemo(() => {
    if (!Array.isArray(data?.businessUnit?.addresses) || !data?.businessUnit?.addresses.length) {
      return [];
    }

    return (data?.businessUnit?.addresses ?? []).filter((address: Address) => address.isBillingAddress);
  }, [data.businessUnit]);

  const getBillingAddressById = (id?: string) => {
    return billingAddresses.find((address: Address) => address.addressId === id);
  };
  const defaultShippingAddress = useMemo(() => {
    return (data.businessUnit?.addresses ?? []).find((address: Address) => address.isDefaultShippingAddress);
  }, [data.businessUnit]);

  const defaultBillingAddress = useMemo(() => {
    return (data.businessUnit?.addresses ?? []).find((address: Address) => address.isDefaultBillingAddress);
  }, [data.businessUnit]);

  const setDefaultShippingAddress = useCallback(
    async (addressId: string): Promise<ErrorType> => {
      const response = await sdk.callAction<BusinessUnit>({
        actionName: 'businessUnit/setDefaultShippingAddress',
        payload: { addressId },
      });

      if (!response.isError) {
        await mutate('/action/businessUnit/getBusinessUnitInfo');
      }

      return response.isError
        ? {
            isError: true,
            message: formatAccountMessage({
              id: 'default.address.change.error',
              defaultMessage: `We are sorry, but we encountered an issue while saving your address default settings. Please log out of the site and try again. If the issue persists, contact your Account Manager.`,
            }),
          }
        : {
            isError: false,
            message: formatAccountMessage({
              id: 'default.address.change',
              defaultMessage: 'Your default address has been updated!',
            }),
          };
    },
    [formatAccountMessage],
  );

  const setDefaultBillingAddress = useCallback(
    async (addressId: string): Promise<ErrorType> => {
      const response = await sdk.callAction<BusinessUnit>({
        actionName: 'businessUnit/setDefaultBillingAddress',
        payload: { addressId },
      });

      if (!response.isError) {
        await mutate('/action/businessUnit/getBusinessUnitInfo');
      }

      return response.isError
        ? {
            isError: true,
            message: formatAccountMessage({
              id: 'default.address.change.error',
              defaultMessage: `We are sorry, but we encountered an issue while saving your address default settings. Please log out of the site and try again. If the issue persists, contact your Account Manager.`,
            }),
          }
        : {
            isError: false,
            message: formatAccountMessage({
              id: 'default.address.change',
              defaultMessage: 'Your default address has been updated!',
            }),
          };
    },
    [formatAccountMessage],
  );

  return {
    businessUnit: data.businessUnit,
    businessUnitDataFetchError: data.error,
    isLoading: data.isLoading,
    shippingAddresses,
    billingAddresses,
    defaultShippingAddress,
    defaultBillingAddress,
    setDefaultShippingAddress,
    setDefaultBillingAddress,
    customInvoiceTerms,
    getBillingAddressById,
    showAccountsReceivable,
    showCreditCards,
    showCreditCardsAndAR,
  };
};

export default useBusinessUnit;
