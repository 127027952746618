import { useCallback, useEffect, useMemo, useState } from 'react';
import { FeatureFlag } from 'shared/types/FeatureFlags';
import useSWR from 'swr';
import { sdk } from 'sdk';
import isEqual from 'lodash/isEqual';

type FeatureFlagReturnValue = {
  featureFlagsList: FeatureFlag[];
  isFeatureEnabled: (featureFlag: string, pageData?: any) => boolean | undefined;
  setFeatureFlagList: (featureFlag: FeatureFlag[]) => void;
};
type FeatureFlagResponse = {
  isError: boolean;
  data: FeatureFlag[];
};

export const enum FEATURE_FLAG_LIST {
  ACCOUNT = 'isAccountActive',
  HOME_PAGE = 'isHomePageActive',
  CHECKOUT = 'isCheckoutActive',
  PDP = 'isPDPActive',
  CDP = 'isCDPActive',
  CART = 'isCartActive',
}

export const useFeatureFlags = (): FeatureFlagReturnValue => {
  const [featureFlagsList, setFeatureFlagList] = useState<FeatureFlag[]>([]);
  const result = useSWR('/action/account/getFeatureFlagList', async () => {
    const res = await sdk.callAction<FeatureFlagResponse>({
      actionName: 'account/getFeatureFlagList',
    });

    return res.isError ? ({ isError: true, data: [] } as FeatureFlagResponse) : res;
  });

  useEffect(() => {
    if (!result?.data?.isError) {
      const featureFlags = result?.data?.data as FeatureFlag[];
      if (!isEqual(featureFlags, featureFlagsList) && featureFlags) {
        setFeatureFlagList(featureFlags);
      }
    }
  }, [result]);

  const getPageFeatureFlags = (pageData: any) => {
    return pageData?.data?.data?.dataSource?.featureFlags || [];
  };

  const isFeatureEnabled = (feature: string, pageData: any) => {
    const flagsList = featureFlagsList.length > 0 ? featureFlagsList : getPageFeatureFlags(pageData);
    if (flagsList.length > 0) {
      if (!isEqual(flagsList, featureFlagsList)) {
        setFeatureFlagList(flagsList);
      }
    }
    const flag: FeatureFlag | undefined = flagsList?.find((savedFeature: FeatureFlag) => savedFeature.name == feature);
    return flag && flag.isEnabled;
  };

  return {
    featureFlagsList,
    isFeatureEnabled,
    setFeatureFlagList,
  };
};
