import { FC, createContext, useContext, useState } from 'react';
import { DataProps } from 'shared/types/nav';

export interface StudioDataContextProps {
  data: DataProps;
  setData: (data: DataProps) => void;
}

const StudioDataContext = createContext<StudioDataContextProps | undefined>(undefined);

const StudioDataProvider: FC = ({ children }) => {
  const [data, setData] = useState({});

  const value = {
    data,
    setData,
  };
  return <StudioDataContext.Provider value={value}>{children}</StudioDataContext.Provider>;
};

export const useStudioDataContext = (): StudioDataContextProps => {
  const context = useContext(StudioDataContext);
  if (context === undefined) {
    throw new Error('useStudioDataContext must be used within a StudioDataProvider');
  }
  return context;
};

export { StudioDataContext, StudioDataProvider };
