import { useMemo, useState, useEffect } from 'react';
import useSWR from 'swr';
import { sdk } from 'sdk';

type ProjectSettingsReturnValue = {
  result: any;
  customerSupportEmail: string;
};

type ProjectSettingsResponse = {
  isError: boolean;
  data: any;
};

export const useProjectSettings = (): ProjectSettingsReturnValue => {
  const [customerSupportEmail, setCustomerSupportEmail] = useState('strategicsales@lululemon.com');
  const { data: result, error } = useSWR('/action/account/getProjectSettingList', async () => {
    const res = await sdk.callAction<ProjectSettingsResponse>({
      actionName: 'account/getProjectSettingList',
    });
    return res.isError ? ({ isError: true, data: [] } as ProjectSettingsResponse) : res;
  });

  useEffect(() => {
    if (result && !error) {
      const email = result?.data?.customer_support_email || customerSupportEmail;
      setCustomerSupportEmail(email);
    } else if (error) {
      console.error('Error fetching project settings:', error);
    }
  }, [result, error]);
  return {
    result,
    customerSupportEmail: customerSupportEmail,
  };
};
