import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { Account, PasswordResetResponse, LoginReturn } from 'shared/types/account';
import { sdk } from 'sdk';
import { GetAccountResult, UpdateAccount, UseAccountReturn } from './types';
import { Role } from 'shared/types/businessUnit';
import { AccountUpdateReturn } from 'shared/types/account/Account';
import { removeApplicationData } from 'helpers/utils/requestHelper';
import { getRetryServerOptions } from 'helpers/utils/requestHelper';

const useAccount = (): UseAccountReturn => {
  const extensions = sdk.composableCommerce;
  let role: Role;
  let userPermissions: string[] = [];
  const result = useSWR('/action/account/getAccount', () => extensions.account.getAccount());
  const data = useMemo(() => {
    if (result.data?.isError) return { loggedIn: false, accountLoading: false, error: result.error };

    const account = (result.data?.data as GetAccountResult)?.account as Account;
    if (account?.role?.key && account?.permissions) {
      role = account.role;
      userPermissions = account.permissions;
    }

    if (account?.id) {
      return { account, loggedIn: true, accountLoading: false, role: role, permissions: userPermissions };
    } else {
      return {
        loggedIn: false,
        account: undefined,
        accountLoading: true,
        error: result.error,
      };
    }
  }, [result]);

  const isUserLoggedIn = !!data?.loggedIn;

  const getUserRoleAndPermissions = async () => {
    const response = await extensions.account.getAccount();

    if (response.isError) {
      return {} as Account;
    }
    const res = await sdk.callAction<Account>({
      actionName: 'account/getUserRoleAndPermissions',
      payload: { account: response.data.loggedIn ? response.data.account : {} },
    });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  };

  const shippingAddresses = useMemo(() => {
    if (!data.account) return [];

    return (data.account.addresses ?? []).filter((address) => address.isShippingAddress);
  }, [data.account]);

  const billingAddresses = useMemo(() => {
    if (!data.account) return [];

    return (data.account.addresses ?? []).filter((address) => address.isBillingAddress);
  }, [data.account]);

  const defaultShippingAddress = useMemo(() => {
    return data.account?.addresses?.find((address) => address.isDefaultShippingAddress);
  }, [data.account]);

  const defaultBillingAddress = useMemo(() => {
    return data.account?.addresses?.find((address) => address.isDefaultBillingAddress);
  }, [data.account]);

  const login = async (email: string, password: string, remember?: boolean): Promise<LoginReturn> => {
    const extensions = sdk.composableCommerce;

    const payload = {
      email,
      password,
      remember,
    };

    const res: any = await extensions.account.login(payload);

    mutate('/action/account/getAccount');

    const { isError, error: { message = '' } = {} } = res;

    if (isError) {
      const error = { isError, message };
      return { res: undefined, error };
    }
    return { res: res.data, error: undefined };
  };

  const logout = async () => {
    try {
      await extensions.account.logout();
      removeApplicationData();
      mutate('/action/account/getAccount');
      mutate('/action/cart/getCart');
    } catch (e) {
      console.info(`Logout Error: ${JSON.stringify(e)}`);
    }
  };

  const confirm = async (token: string): Promise<Account> => {
    const res = await extensions.account.confirm({ token });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  };

  const changePassword = async (oldPassword: string, newPassword: string): Promise<AccountUpdateReturn> => {
    const res: any = await extensions.account.changePassword({ oldPassword, newPassword });

    return { ...res.data, error: res.isError ? res.error : undefined };
  };

  const requestPasswordReset = async (email: string): Promise<PasswordResetResponse> => {
    const res = await extensions.account.requestResetPassword({ email });

    const result: any = res;

    return {
      isError: result?.isError,
      message: result?.error?.message,
    };
  };

  const resetPassword = async (token: string, newPassword: string): Promise<Account> => {
    const res = await extensions.account.resetPassword({ token, newPassword });
    // mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  };

  const update = async (account: UpdateAccount): Promise<Account> => {
    const res = await extensions.account.updateAccount(account);

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  };

  const updatePhoneNumber = async (phoneNumber: string, isRetry = false) => {
    const response = await extensions.account.getAccount();

    if (response.isError) return {} as Account;

    const res = await sdk.callAction<Account>({
      actionName: 'account/updatePhoneNumber',
      payload: { account: response.data.loggedIn ? response.data.account : {}, phoneNumber },
      serverOptions: isRetry ? getRetryServerOptions.serverOptions : undefined,
    });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  };

  const setDefaultBillingAddress = async (addressId: string): Promise<Account> => {
    const res = await extensions.account.setDefaultBillingAddress({ addressId });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  };

  const setDefaultShippingAddress = async (addressId: string): Promise<Account> => {
    const res = await extensions.account.setDefaultShippingAddress({ addressId });

    mutate('/action/account/getAccount');

    return res.isError ? ({} as Account) : res.data;
  };

  const getUserList = async () => {
    const response = await extensions.account.getAccount();

    if (response.isError) return {} as Account;
    const res = await sdk.callAction<Account[]>({
      actionName: 'businessUnit/fetchUserListData',
      payload: { account: response.data.loggedIn ? response.data.account : {} },
    });
    mutate('/action/businessUnit/fetchUserListData');

    return res.isError ? ({} as Account) : res.data;
  };

  const resetPasswordTokenDetails = async (token: string): Promise<Account> => {
    const res = await sdk.callAction<Account>({
      actionName: 'account/fetchResetPasswordTokenDetails',
      payload: { token },
    });

    mutate('/action/account/fetchResetPasswordTokenDetails');
    return res.isError ? ({} as Account) : res.data;
  };
  return {
    ...data,
    shippingAddresses,
    billingAddresses,
    defaultShippingAddress,
    defaultBillingAddress,
    login,
    logout,
    confirm,
    changePassword,
    requestPasswordReset,
    resetPassword,
    update,
    updatePhoneNumber,
    setDefaultBillingAddress,
    setDefaultShippingAddress,
    getUserList,
    getUserRoleAndPermissions,
    resetPasswordTokenDetails,
    isUserLoggedIn,
  };
};

export default useAccount;
