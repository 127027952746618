import { NgcCart } from 'shared/types/ngcCart';
import { CartProduct } from 'types/cart';

/**
 * Transforms an array of `Product` objects into a structured list format for displaying products.
 *
 * @param {CartProduct[]} products - Array of products to be transformed.
 * @param {string} type - Type of component
 * @param {string} id - Unique id of component
 * @returns {Object[]} - Array of formatted product collection objects, each including SKU details, pricing, and product information.
 */

export const toProductCart = (products: CartProduct[], type: string, id: string) => {
  const formatPrice = (price: number) => price.toFixed(2);

  const collectionList = products.map((product) => {
    const item = {
      price: [
        {
          isSale: false,
          displaySale: formatPrice(product.wholesalePrice) ?? '',
          displayRegular: formatPrice(product.retailPrice) ?? '',
          saleWithoutTaxShipping: formatPrice(product.wholesalePrice) ?? '',
          regularWithoutTaxShipping: formatPrice(product.retailPrice) ?? '',
          taxOnly: '', // TODO: To be confirmed
        },
      ],
      sku: product.lineItems[0].skuId,
      size: product.size ?? '',
      quantity: product.quantity ?? 1,
    };

    const productPayload = {
      type: type,
      id: id,
      productList: [
        {
          skuList: [item],
          name: {
            unified: '', // This is something related with PCM. We can leave it as “N/A” for now.
            localized: product.productName ?? '',
          },
          categoryUnifiedId: '', // No category id for productCart
          unifiedId: '', // This is something related with PCM. We can leave it as “N/A” for now.
          productId: product.productId ?? '',
        },
      ],
    };
    return productPayload;
  });

  return collectionList;
};

/**
 * Transforms an array of `Product` objects into a structured list format for displaying products.
 *
 * @param {NgcCart} products - Cart to be transformed.
 * @param {string} type - Type of component
 * @param {string} id - Unique id of component
 * @returns {Object[]} - Array of formatted product collection objects, each including SKU details, pricing, and product information.
 */

export const toProductPageDisplayedCart = (products: NgcCart, type: string, id: string) => {
  const formatPrice = (price: number) => price.toFixed(2);
  if (products.lineItems && products?.lineItems.length > 0) {
    const productList = products?.lineItems.map((lineItem) => {
      const item = {
        name: {
          unified: '', // This is something related with PCM. We can leave it as “N/A” for now.
          localized: lineItem.productName ?? '',
        },
        categoryUnifiedId: '', // No category id for productCart
        unifiedId: '', // This is something related with PCM. We can leave it as “N/A” for now.
        productId: lineItem.productId ?? '',
        skuList: [
          {
            price: [
              {
                isSale: false,
                displaySale: formatPrice(lineItem.lineItemUnitPrice.salePrice) ?? '',
                displayRegular: formatPrice(lineItem.lineItemUnitPrice.listPrice) ?? '',
                saleWithoutTaxShipping: formatPrice(lineItem.lineItemUnitPrice.salePrice) ?? '',
                regularWithoutTaxShipping: formatPrice(lineItem.lineItemUnitPrice.listPrice) ?? '',
                taxOnly: '',
              },
            ],
            sku: lineItem.skuId,
            size: lineItem.size ?? '',
            quantity: lineItem.quantity ?? 1,
          },
        ],
      };
      return item;
    });

    const productPayload = [
      {
        type: type,
        id: id,
        productList: productList,
      },
    ];

    return productPayload;
  }
};
