// TypeScript code for defining enums related to event categories, component types, and error types.

export enum EVENT_CATEGORY {
  'COMPONENT_EVENT' = 'COMPONENT_EVENT',
  'PAGE_VIEW' = 'PAGE_VIEW',
  'APP_RESPONSE' = 'APP_RESPONSE',
  'ERROR' = 'ERROR',
  'PURCHASE' = 'PURCHASE',
  'SEARCH' = 'SEARCH',
  'FILTER_INTERACTION' = 'FILTER_INTERACTION',
  'PRODUCT_INTERACTION' = 'PRODUCT_INTERACTION',
  'PAGE_PRODUCTS_DISPLAYED' = 'PAGE_PRODUCTS_DISPLAYED',
}

export enum EVENT_TYPE {
  'IMPRESSION' = 'impression',
  'INTERACTION' = 'interaction',
}

declare global {
  interface Window {
    MwaAnalytics: {
      trackEvent: (name: keyof typeof EVENT_CATEGORY, data: any) => void;
      EVENT_CATEGORY: typeof EVENT_CATEGORY;
      EVENT_TYPE: typeof EVENT_TYPE;
    };
  }
}

// Defining the return type of useAnalytics function
type UseAnalyticsResult = {
  trackEvent: (name: keyof typeof EVENT_CATEGORY, data: any) => ReturnType<typeof window.MwaAnalytics.trackEvent>;
  EVENT_CATEGORY: typeof EVENT_CATEGORY;
  EVENT_TYPE: typeof EVENT_TYPE;
};

export const useAnalytics = (): UseAnalyticsResult => {
  const trackEvent = (name: keyof typeof window.MwaAnalytics.EVENT_CATEGORY, data: any) => {
    const eventCategory = typeof window !== 'undefined' && window?.MwaAnalytics?.EVENT_CATEGORY[name];
    if (eventCategory) {
      window.MwaAnalytics.trackEvent(eventCategory, data);
    } else {
      const intervalTime = setInterval(() => {
        const delayedEventCategory = typeof window !== 'undefined' && window?.MwaAnalytics?.EVENT_CATEGORY[name];
        if (delayedEventCategory) {
          window.MwaAnalytics.trackEvent(delayedEventCategory, data);
          clearInterval(intervalTime);
        }
      }, 1000);
    }
  };

  return {
    trackEvent,
    EVENT_CATEGORY,
    EVENT_TYPE,
  };
};
