import { useCallback } from 'react';
import {
  BulkOrderResponseMapper,
  OrderResponseMapper,
  OrdersResponseMapper,
  RequestObjectOrderMapper,
} from 'shared/types/cart/Order';
import { sdk } from 'sdk';
import { useOrdersReturn } from './types';
import useBusinessUnit from '../useBusinessUnit';

const useOrders = (): useOrdersReturn => {
  const { businessUnit } = useBusinessUnit();
  const getOrders = useCallback(async (requestOrderObject: RequestObjectOrderMapper) => {
    const res = await sdk.callAction({
      actionName: 'cart/getOrders',
      payload: requestOrderObject,
    });
    return res as OrdersResponseMapper;
  }, []);

  const getOrder = useCallback(async (orderId: string) => {
    const res = await sdk.callAction({
      actionName: 'cart/getOrder',
      payload: { orderId: orderId },
    });
    return res as OrderResponseMapper;
  }, []);

  const getBulkOrdersForExport = useCallback(async () => {
    const requestOrderObject = {
      offset: 0,
      limit: 100,
      sortString: 'createdAt desc',
      businessUnit: businessUnit.key,
    };

    const res = await sdk.callAction({
      actionName: 'cart/getBulkOrders',
      payload: requestOrderObject,
    });

    return res as BulkOrderResponseMapper;
  }, []);

  const exportInvoice = useCallback(async (invoiceUrl: string) => {
    const res = await sdk.callAction({
      actionName: 'cart/exportInvoice',
      payload: { filename: invoiceUrl },
    });

    return res as OrderResponseMapper;
  }, []);

  return {
    getOrders,
    getOrder,
    getBulkOrdersForExport,
    exportInvoice,
  };
};

export default useOrders;
